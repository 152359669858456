/* Sidenav */
/* --------------------- */
#adminmenu {
  background-color: transparent;
}

#adminmenuwrap,
#adminmenuback {
  background-color: rgb(169, 26, 182);
}

#adminmenu li.wp-has-current-submenu a.wp-has-current-submenu,
#adminmenu li.menu-top:hover,
#adminmenu li.opensub > a.menu-top,
#adminmenu li > a.menu-top:focus {
  background: rgb(122, 11, 137);
}

#adminmenu .wp-submenu {
  background-color: rgb(76, 13, 105);
}

/* Top nav */
#wpadminbar {
  background: linear-gradient(72.45deg, rgb(169, 26, 182) 12.07%, rgb(122, 11, 137) 88.57%) !important;
}

#wpadminbar .ab-top-menu > li.hover > .ab-item,
#wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus,
#wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item,
#wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus {
  background-color: rgb(122, 11, 137);
  color: #fff;
}

#wp-admin-bar-site-name.hover #wp-admin-bar-site-name-default {
  background-color: rgb(169, 26, 182);
}

#wpadminbar .ab-top-menu > li.hover > .ab-item,
#wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus,
#wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item,
#wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus,
#adminmenu li.menu-top:hover,
#adminmenu li.opensub > a.menu-top,
#adminmenu li > a.menu-top:focus,
#adminmenu li a:focus div.wp-menu-image:before,
#adminmenu li.opensub div.wp-menu-image:before,
#adminmenu li:hover div.wp-menu-image:before,
#adminmenu .wp-submenu a:focus,
#adminmenu .wp-submenu a:hover,
#adminmenu a:hover,
#adminmenu li.menu-top > a:focus,
#collapse-button:hover {
  color: #fff !important;
}

.wp-admin #wpadminbar #wp-admin-bar-site-name > .ab-item:before {
  color: #baaed2;
}

#wpadminbar .quicklinks .menupop ul li a span,
#wpadminbar .quicklinks .menupop ul li a strong,
#wpadminbar .quicklinks .menupop.hover ul li a span,
#wpadminbar .quicklinks .menupop.hover ul li a,
#wpadminbar .quicklinks .menupop ul li a,
#wpadminbar.nojs .quicklinks .menupop:hover ul li a {
  color: white !important;
  opacity: 0.8 !important;
}
#wpadminbar .quicklinks .menupop ul li a span img,
#wpadminbar .quicklinks .menupop ul li a strong img,
#wpadminbar .quicklinks .menupop.hover ul li a span img,
#wpadminbar .quicklinks .menupop.hover ul li a img,
#wpadminbar .quicklinks .menupop ul li a img,
#wpadminbar.nojs .quicklinks .menupop:hover ul li a img {
  opacity: 1 !important;
}

#wpadminbar .quicklinks .menupop ul li a:hover,
#wpadminbar .quicklinks .menupop ul li a strong:hover,
#wpadminbar .quicklinks .menupop.hover ul li a:hover,
#wpadminbar .quicklinks .menupop ul li a span:hover,
#wpadminbar .quicklinks .menupop.hover ul li a span:hover,
#wpadminbar.nojs .quicklinks .menupop:hover ul li a:hover {
  color: white !important;
  opacity: 1 !important;
}

.ab-sub-wrapper #wp-admin-bar-wp-logo-default {
  background-color: rgb(122, 11, 137) !important;
}

#wpadminbar .quicklinks .menupop ul.ab-sub-secondary {
  background-color: rgb(76, 13, 105) !important;
}

.ab-sub-wrapper #wp-admin-bar-user-actions {
  background-color: rgb(122, 11, 137) !important;
}

.ab-sub-wrapper #wp-admin-bar-site-name-default {
  background-color: rgb(122, 11, 137) !important;
}

#wp-admin-bar-comments,
#wp-admin-bar-new-content {
  display: none;
}

body.login {
  background: linear-gradient(72.45deg, rgb(169, 26, 182) 12.07%, rgb(122, 11, 137) 88.57%) !important;
}